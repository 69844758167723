<template>
  <div>
    <v-dialog v-model="bookingDialog" max-width="700" persistent scrollable>
      <v-card>
        <v-card-title>new Appointment</v-card-title>
        <v-card-text>
          <v-toolbar dense flat outlined>
            <v-toolbar-title>Offered To</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items style="padding-top: 12px">
              <b>
                {{ _decode(salute) | capitalize }}
                {{ _decode(first) | capitalize }}
                {{ _decode(last) | capitalize }}
              </b>
            </v-toolbar-items>
          </v-toolbar>
          <v-card outlined style="margin: 5px 0px">
            <v-card-text>
              <div class="mylist">
                <ul>
                  <li>
                    <div class="myleft">scheduled date</div>
                    <div class="myright">{{ meet.schedule_date | myDate }}</div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Time</div>
                    <div class="myright">
                      {{ meet.schedule_time_start }}
                      <span style="padding: 0px 5px; text-transform: lowercase">
                        to
                      </span>
                      {{ meet.schedule_time_end }} ({{ meet.max_see }}
                      minutes)
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">location</div>
                    <div class="myright">
                      {{ meet.meeting_typesid }}
                      <span style="padding: 0px 5px; text-transform: lowercase">
                        at
                      </span>
                      {{ meet.location }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Available</div>
                    <div class="myright">{{ meet.accept ? "No" : "Yes" }}</div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Cost</div>
                    <div class="myright">{{ currency(meet.pricing) }}</div>
                    <div class="clearall"></div>
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>

          <v-textarea
              v-model="create.initialComplaints"
              counter="240"
              label="initial complaints"
              outlined
              rows="3"
              style="margin-top: 16px"
          ></v-textarea>

          <div></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  :disabled="$v.create.$invalid"
                  color="blue"
                  small
                  text
                  @click="submit"
                  v-on="on"
              >
                submit
              </v-btn>
            </template>
            <span>Submit</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils";
import {validationMixin} from "vuelidate";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  mixins: [Utils, validationMixin],
  props: {
    bookingDialog: Boolean,
    myId: {type: Number, default: 0},
    salute: {type: String},
    first: {type: String},
    last: {type: String},
  },
  data: () => ({
    create: {initialComplaints: null},
    meet: {
      subscriberId: null,
      schedule_date: null,
      max_see: null,
      schedule_time_start: null,
      schedule_time_end: null,
      location: null,
      meeting_typesid: null,
      accept: null, pricing: null
    },
  }),
  validations: {
    create: {
      initialComplaints: {
        required,
        maxLength: maxLength(240),
      },
    },
  },
  watch: {
    "create.acceptance": function (a) {
      if (a === true) {
        this.isGood = false;
      } else {
        this.isGood = true;
      }
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      console.log(this.myId);
      let self = this;
      Restful.subscribers.available
          .timeDetail(this.myId)
          .then((response) => {
            console.log(response.data);
            this.meet.schedule_time_start = response.data.schedule_time_start;
            this.meet.schedule_time_end = response.data.schedule_time_end;
            this.meet.accept = response.data.accept;
            this.findAvailable(response.data.medics_availableid);
          })
          .catch((error) => {
            console.log(error.response);
            self.loadevent = false;
          });
    },
    findAvailable(id) {
      let self = this;
      Restful.subscribers.available
          .find(id)
          .then((response) => {
            console.log(response.data);
            this.meet.subscriberId = response.data.subscribersid;
            this.meet.schedule_date = response.data.schedule_date;
            this.meet.location = response.data.meeting_location;
            this.meet.meeting_typesid = response.data.meeting_typesid;
            this.meet.max_see = response.data.max_see;
            this.meet.pricing = response.data.pricing;
          })
          .catch((error) => {
            console.log(error.response);
            self.loadevent = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeBook", payload);
    },
    submit() {
      let subscriberId = this.meet.subscriberId;
      let medicsAvailableTimesId = this.myId;
      let initialComplaints = this.create.initialComplaints;
      let data = {subscriberId, medicsAvailableTimesId, initialComplaints};
      console.log("data", data);
      let payload = {id: this.myId, state: false, data: data};
      this.$emit("submitBook", payload);
    },
  },
};
</script>
