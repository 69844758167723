<template>
  <div class="page">
    <!-- v-resize="onResize" -->
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="refresh" text color="primary" v-on="on">
              <v-icon dark>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <!-- 
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text color="success" v-on="on">
              <v-icon size="25" dark>mdi-bell-outline</v-icon>
              <v-badge
                bordered
                content="3"
                overlap
                style="margin: 5px 10px 0px 10px"
              ></v-badge>
            </v-btn>
          </template>
          <span>Request Response Notification</span>
        </v-tooltip> -->
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card flat :loading="loading" loader-height="2">
        <v-toolbar flat dense>
          <v-toolbar-title> Pending Requests </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text color="primary" small> requests all appointments </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="padding: 16px 16px 0px">
          <div class="discart">
            <v-row>
              <v-col cols="12" sm="6" v-for="item in cartItems" :key="item.id">
                <v-card outlined>
                  <v-card-text style="padding: 0 1px">
                    <v-list dense tile flat>
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="capitalize">
                              Schedule with:
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action-text>
                            {{
                              item.medics_available_times.medic.salutations
                                | capitalize
                            }}
                            {{
                              item.medics_available_times.medic.first_name
                                | capitalize
                            }}
                            {{
                              item.medics_available_times.medic.last_name
                                | capitalize
                            }}
                          </v-list-item-action-text>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="capitalize">
                              Location
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action-text>
                            {{
                              item.medics_available_times.meeting_location
                                | capitalize
                            }}
                          </v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="capitalize">
                              meeting type
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action-text>
                            {{
                              item.medics_available_times.meeting_typesid
                                | capitalize
                            }}
                          </v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="capitalize">
                              schedule date
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action-text>
                            {{
                              item.medics_available_times.schedule_date | myDate
                            }}
                          </v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="capitalize">
                              schedule time
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action-text>
                            {{
                              item.medics_available_times.schedule_time_start
                                | myTime
                            }}
                            to
                            {{
                              item.medics_available_times.schedule_time_end
                                | myTime
                            }}
                          </v-list-item-action-text>

                          <v-list-item-action-text>
                            {{
                              item.medics_available_times.accept
                                ? "Not Available"
                                : "Available"
                            }}
                          </v-list-item-action-text>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions style="padding: 0">
                    <v-btn
                      small
                      text
                      color="pink"
                      @click.stop="$set(removeDialog, item.id, true)"
                    >
                      remove
                    </v-btn>
                    <CartRemove
                      v-if="removeDialog[item.id]"
                      :removeDialog="removeDialog[item.id]"
                      :myId="item.id"
                      @closeRemove="closeRemove"
                      @submitRemove="submitRemove"
                    />
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      text
                      color="primary"
                      @click.stop="
                        $set(bookingDialog, item.medics_available_timesid, true)
                      "
                    >
                      request an appointment
                    </v-btn>
                    <ScheduleBooking
                      v-if="bookingDialog[item.medics_available_timesid]"
                      :bookingDialog="
                        bookingDialog[item.medics_available_timesid]
                      "
                      :myId="item.medics_available_timesid"
                      :salute="
                        _encode(item.medics_available_times.medic.salutations)
                      "
                      :first="
                        _encode(item.medics_available_times.medic.first_name)
                      "
                      :last="
                        _encode(item.medics_available_times.medic.last_name)
                      "
                      @closeBook="closeBook"
                      @submitBook="submitBook"
                    />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Util from "../../mixins/utils";
import CartRemove from "../../components/appointments/cart/remove.vue";
import ScheduleBooking from "../../components/appointments/schedules/booking.vue";

export default {
  components: { ScheduleBooking, CartRemove },
  mixins: [Util, WinScroll("position")],
  data() {
    return {
      loading: false,
      removeDialog: {},
      bookingDialog: {},
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "patient.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Pending Requests",
          to: { name: "patient.pending" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      cartItems: [],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {},
  created() {
    this.myLot();
  },
  methods: {
    refresh() {
      this.myLot();
    },
    myLot() {
      this.loading = true;
      let self = this;
      Restful.cart
        .client()
        .then((response) => {
          console.log(response);
          this.cartItems = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadevent = false;
          self.loading = true;
        });
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeBook(p) {
      this.$set(this.bookingDialog, p.id, p.state);
    },
    submitBook(p) {
      console.log(p);
      this.snack.bar = true;
      Restful.medics.patient.request
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.myLot();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.bookingDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      console.log(p);
      let self = this;
      Restful.cart
        .remove(p.id)
        .then((response) => {
          console.log(response);
          this.myLot();
        })
        .catch((error) => {
          console.log(error.response);
          self.loadevent = false;
        });

      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
